import React, { Component } from 'react'
// import { Link } from 'gatsby'
import _ from 'lodash'

import {
    Dropdown
} from 'react-bootstrap'
// import { StaticQuery, graphql, Link } from 'gatsby'
import { withNamespaces } from 'react-i18next'

// import LocalImage from '../../components/local-image'

class FilterDropdown extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!

        this.state = {
            filterSel: {}
        }

        this.handleOnClick = this.handleOnClick.bind(this)
    }

    handleOnClick(ek){
        // console.log(`handleOnClick ek = ${ek}`)
        this.props.handleFilter(ek,this.props.mode)
        // console.log(`this.state.filterSel === `,this.state.filterSel)
        let filtersel = {}
        _.map( this.state.filterSel, (v,i)=>{
            // console.log(`v = ${v} i = ${i}`)
            filtersel[i] = (i===ek) ? 'active' : ''
        })
        // console.log(`filtersel === `,filtersel)
        this.setState({ filterSel: filtersel })
    }


    render() {


        const t = this.props.t
        const _t = this.props.trans
        // const lang = this.props.lng
        let seltext = this.props.seltext || t('PT0200')

        let data = this.props.filterlist
        let mode = this.props.mode

        // console.log(`===== Filter dropdown (mode: ${mode}) > this.props ====`, this.props)
        // console.log(`===== Filter dropdown (mode: ${mode}) > data ====`, data)

        return (
            <>
            <Dropdown className={`filter filter-${mode}`} alignRight  onSelect={this.handleOnClick}>
                <Dropdown.Toggle id={`filter-${mode}`} variant="filter">
                    {seltext}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href={`#`} eventKey={0}>{t('PT0201')}</Dropdown.Item>
                {_.map( data, (item,i) => {
                    let result = null
                    // console.log(`====> Filter item set : href #${item.slug} - ${t(item.nameid)}`)
                    let selfilter = (typeof this.state.filterSel[i]==='undefined') ? "" : this.state.filterSel[i]
                    switch (mode){
                        case "newsyear":
                            result = <Dropdown.Item className={`${selfilter}`} href={`#`} eventKey={item} key={`${mode}-opt-${item}`} >{item}</Dropdown.Item>
                            break

                        case "newsmonth":
                            result = <Dropdown.Item className={`${selfilter}`} href={`#`} eventKey={i} key={`${mode}-opt-${i}`} >{item}</Dropdown.Item>
                            break

                        case "faction":
                        default:
                            let slug = _t[item.nameid].match(/[A-Za-z0-9]+/g).join('_').toLowerCase()
                            // console.log(`====> this.state.filterSel [${i}] - `,this.state.filterSel[i])
                            result = <Dropdown.Item className={`${selfilter}`} href={`#`} eventKey={item.id} key={`${slug}-opt-${i}`} >{item.name}</Dropdown.Item>
                    }
                    return (result)}
                )}
                </Dropdown.Menu>
            </Dropdown>
            </>
        )
    }
}

export default withNamespaces()(FilterDropdown)
