const _ = require('lodash')

const { getAxios } = require('./get-axios-handler')
const dbset = require('./tablelist.json')

exports.getAxiosArmies = ( dbargs, mode ) => {

    return new Promise(resolve => {
        
        getAxios( process.env.MDAPI_URL , { spreadsheetid:process.env.MDAPI_SHEET_ID, ...dbset.Armies, ...dbargs }).then( list => {
            
            let data = {}
            let filter = {  'Hidden':0  }

            if (mode) filter['TypeName'] = mode
            console.log(`👉🏻 Return under filter = `, filter)
            
            data = _.filter( list, filter )

            resolve(data)
        })

    })

}
