import React, { Component } from 'react'
// import _ from 'lodash'

import {
    Row
} from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'
// import LocalImage from '../../components/local-image'

class EventsList extends Component {
    // constructor(props) {
    //     super(props);
    //     // Don't call this.setState() here!
    // }

    render() {
        const t = this.props.t
        const lang = this.props.lng
        const eventsList = this.props.eventslist

        return (
            <Row className="plist d-flex align-items-center flex-wrap">
            { 
                eventsList.map((item,i) => {
                    let hs = (i<this.props.pts) ? ' show ' : 'hidden'
                    let slug = (item.NameEn)? (item.NameEn).match(/[A-Za-z0-9]+/g).join('_').toLowerCase() : item.EventID
                    return (
                        <a href={`/${lang}/map/events/${slug}`} key={`event-l-${i}`} className={`col-12 col-md-4 col-lg-3 ${hs}`} rel="noopener ">
                            {/* <div className="plist-img text-center">
                                <LocalImage name={item.typeobj.IconImage.split('.')[0]} attr={{className: "img-fluid", alt:"Factions"}} />
                            </div> */}
                            <div className="plist-box">
                                {t(item.Name)}
                            </div>
                        </a>
                    )}
                )
            }
            </Row> 
        )
    }
}

export default withNamespaces()(EventsList)
