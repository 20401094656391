// import moment from 'moment'
// const _ = require(`lodash`)
const moment = require(`moment`)

exports.getCache = () => {
    
    let cachetime = process.env.CACHE_TIME || 1
    let dataLastupdateStore = localStorage.getItem('version') || moment(+new Date()).subtract(2, 'days')
    let dataLastupdate = moment(+dataLastupdateStore)
    let _now = moment(+new Date())

    let cachetimeout = _now.diff(dataLastupdate, process.env.CACHE_UNIT ) >= cachetime || false


    // console.log(`cachetime = ${cachetime} , dataLastupdateStore ( ${dataLastupdateStore} ) = ` )
    // console.log(`A>localStorage.getItem('version') = ${moment(+(localStorage.getItem('version'))).format("DD-MM-YYYY HH:mm:ss")} - ${localStorage.getItem('version')}`)
    // console.log(`B>moment(+new Date()).subtract(2, 'days') = ${moment(+(moment(+new Date()).subtract(2, 'days'))).format("DD-MM-YYYY HH:mm:ss")} - ${moment(+new Date()).subtract(2, 'days')}` )
    console.log(`Check time diff ( ${dataLastupdate} , ${cachetime} - ${process.env.CACHE_UNIT} ) = ${_now.diff(dataLastupdate, process.env.CACHE_UNIT )} Result : cachetimeout = ${cachetimeout}`)

    return cachetimeout

}