const axios = require('axios')

exports.getAxios = ( url, db ) => {

    return new Promise(resolve => {

        axios.post( url, db ).then( json => {
            let data = json.data.data
            // DEBUG :
            console.log(`👉👉👉👉👉 getAxios : Resolve data 👉👉👉👉👉 (${data.length}) @ setting =`, db)
            // console.log(data)
            resolve(data)
        })
        .catch((error)=>{  console.log(`😱 Axios request failed: ${error}`)    })

    })

}
