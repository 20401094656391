const _ = require('lodash')

const { getAxios } = require('./get-axios-handler')
const dbset = require('./tablelist.json')

exports.getAxiosEvents = ( dbargs ) => {

    return new Promise(resolve => {

        getAxios( process.env.MDAPI_URL , { spreadsheetid:process.env.MDAPI_SHEET_ID, ...dbset.Events, ...dbargs }).then( list => {
            
            let data = {}
            data = _.filter( list, { 'Hidden':0 } )

            resolve(data)
        })

    })

}
