import React, { Component } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import {
    Breadcrumb, Form,
    Row, Col, Container
} from 'react-bootstrap'
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

import { getCache } from '../../util/get-cache-handler'
import { getTrans } from '../../util/get-trans-handler'
import { getAxiosArmies } from '../../util/get-axios-armies-handler'
import { getAxiosEvents } from '../../util/get-axios-events-handler'
// import { getAxiosEventType } from '../../util/get-axios-eventype-handler'

import Layout from '../../components/layout'
import FilterDropdown from '../../components/filter-dropdown'
import EventsList from '../../components/map/events_list'


import '../../css/events.css'


class EventsListPage extends Component {

    constructor(props, context) {
        super(props, context);
        // Don't call this.setState() here!

        this.state = {
            trans: [],
            eventsFullList: [],
            eventsList: [],
            filterList:[],
            filterLabel: '',
            filterDisplay: '',
            sortOption: 'sort-latest',

            postsToShow:4,
            postsToLoad:4,
        }

    }

    async componentDidMount() {

        let self = this

        /////// Initial translate //////////
        const enList = (self.props.data.en.edges)
        let _t = await getTrans(enList)
        self.setState({trans: _t})

        /////// Setup Data //////////
        let eventslist = JSON.parse(localStorage.getItem('eventslist')) || undefined
        let armieslist = JSON.parse(localStorage.getItem('armieslist')) || undefined

        // Get Local Cache
        if ( typeof eventslist === 'undefined' || typeof armieslist === 'undefined' || getCache() ) {
            console.log(`load ajax`)
            eventslist = await getAxiosEvents({ o:1000 })
            armieslist = await getAxiosArmies({ o:1000 })

            localStorage.setItem('eventslist', JSON.stringify(eventslist))
            localStorage.setItem('armieslist', JSON.stringify(armieslist))

            localStorage.setItem('version', +(new Date()))
        }


        /////// Display Data //////////

        // Setup Faction List
        let factionslist =  ( armieslist ) ?  _.reject( armieslist, {'TypeName':'Division'} ) : null
        // console.log(`==== factionslist ====`,factionslist)

        // Setup Event List
        if ( (eventslist) && (factionslist) ){

            let filterlist = []
            let _eventslist = eventslist
            _.map( eventslist, (obj,i) => {
                filterlist = _.chain(filterlist)
                            .concat(obj.Factions)
                            .uniq()
                            .value()
            })

            _eventslist = _.chain(_eventslist)
                        .map( obj =>{
                            obj["NameEn"] = _t[obj.Name]
                            return obj
                        })
                        .orderBy(  ['DisplayOrder','PublishDate'], ['desc','desc'] )
                        .value()
            // DEBUG :
            // console.log(`_eventslist ------>>>> `, _eventslist)

            // Set up filter list for dropdown ////
            let _filterlist = {}
            _.map( filterlist, (obj) => {
                let _id = Number(obj)
                let faction = _.find(factionslist, { 'TypeName': 'Faction', 'ArmieID': _id })
                // console.log(`factionslist.find('TypeName': 'Faction', 'ArmieID': obj) = ${faction}`)
                _filterlist[_id] = {
                    id: _id,
                    nameid : faction.Name,
                    logo : faction.Logo
                }
            })
            // console.log(_filterlist)

            self.setState({
                eventsList: _eventslist,
                eventsFullList: _eventslist,
                filterList: _filterlist,
            })
        }

        window.addEventListener(`scroll`, this._handleScroll)
        let mq = document.getElementsByClassName('wrapper')[0].offsetWidth
        // console.log(`news-mount - mq = ${mq}`)
        if (mq>1138) this.setState({ postsToShow: 8, postsToLoad: 4 })
        else if (mq>718) this.setState({ postsToShow: 6, postsToLoad: 3 })
        else this.setState({ postsToShow: 4, postsToLoad: 2 })

    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this._handleScroll)
    }

    _update() {
        //     // console.log(`news-update`)
        const distanceToBottom = document.querySelector('#footer .base').offsetTop
        if (distanceToBottom < window.scrollY+window.innerHeight  && this.state.postsToShow < _.size(this.state.eventsList) ) {
            // console.log(`d to btm: ${distanceToBottom} < w.sY: ${window.scrollY} / ${window.scrollY+window.innerHeight}  && pts: ${this.state.postsToShow} < ${_.size(this.state.eventsList)} `)
            this.setState({ postsToShow: this.state.postsToShow + this.state.postsToLoad })
        }
        this.ticking = false
    }

    _handleScroll = () => {
        // console.log(`news-scrollhandle`)
        if (!this.ticking) {
            this.ticking = true
            requestAnimationFrame(() => this._update())
        }
    }

    handleSorting = (e) => {
        console.log(`handleSorting e.target.value = ${e.target.value}  ` )
        let _eventslist, set = []
        switch (e.target.value){
            case 'sort-az':
                set = [['NameEn'], ['asc']]
                break
            case 'sort-story':
                set = [['StoryYear'], ['asc']]
                break
            case 'sort-latest':
            default:
                set = [['DisplayOrder','PublishDate'], ['desc','desc']]
        }
        _eventslist = _.orderBy( this.state.eventsList, set[0], set[1] )

        this.setState({
            sortOption: e.target.value ,
            eventsList: _eventslist
        })
    }

    handleFilter = (ek) => {
        console.log(`handleFilter eventkey value =  ${ek}`,  )
        // console.log(`this.state.eventsFullList = `, this.state.eventsFullList)

        let _eventslist = []
        let label = ''
        if (ek > 0){
            _.map( this.state.eventsFullList, (obj,i) =>{
                // console.log( `data[${i}]["Factions"] = ${this.state.eventsList[i]["Armies"]}` )
                this.state.eventsFullList[i]["Factions"].forEach( (aid)=>{
                    // console.log(`data[${i}]["Armies"] = aid = ${aid} `)
                    if (aid===ek) {
                        label = this.state.filterList[aid]['name']
                        _eventslist.push(obj)
                    }
                })
            })

        }else{
            _eventslist = this.state.eventsFullList
            label = this.state.filterLabel
        }
        this.setState({
            filterDisplay:label,
            eventsList: _eventslist,
        })
    }


    render() {

        // let _t = this.state.trans
        const lang = this.props.pageContext.lng

        // DEBUG :
        // console.log(` ==== this.props : events list page ==== `)
        // console.log( this.props )
        // console.log(`======= this.state.filterList ======`, this.state.filterList )
        // console.log(`======= this.state.eventsList = length: ${this.state.eventsList.length} ======`, this.state.eventsList )

        return (
            <NamespacesConsumer>
            {(t, { i18n }) => {
                // const lang = i18n.language.toLowerCase()
                if (_.size(this.state.filterList)>0 && this.state.filterLabel==='' && this.state.filterDisplay===''){
                    _.map(this.state.filterList, obj =>{
                        obj['name'] = t(obj.nameid)
                    })
                    this.setState({
                        filterLabel: t('PT0200'),
                        filterDisplay:t('PT0200'),
                    })
                }

                return (

                    <Layout siteData={ { title:`${t("MU000")} | ${t("MU005")} | ${t('MU008')} | ${t("PT6700")} `, url:this.props.location.href } }>
                        <div id="EventsList" className="body-page ">
                            <Container className="wrapper line-head">
                                <Breadcrumb>
                                    <Breadcrumb.Item href={`/${lang}/map/`}> {t('MU008')} ></Breadcrumb.Item>
                                </Breadcrumb>
                            </Container>

                            <main role="main" className="main">
                                <Container className="wrapper">
                                    <Row>
                                        <Col xs={6} md={12} className="pr-0">
                                            <div className="ptitle">{t('PT6700')}</div>
                                        </Col>
                                        <Col xs={6} className="pl-0 text-right order-md-2 ">
                                            {
                                                (this.state.filterList==null) ?
                                                    null
                                                    :
                                                    <FilterDropdown mode="faction" seltext={this.state.filterDisplay} trans={this.state.trans}
                                                        filterlist={this.state.filterList} handleFilter={this.handleFilter.bind(this)} />
                                            }
                                        </Col>
                                        <Col xs={12} md={6} className="order-md-1 pt-3">
                                            <Form className="form-sorting">
                                                <Form.Label>{t('PT0100')}</Form.Label>
                                                <Form.Check inline label={t('PT0101')} name="sorting" type="radio" value="sort-az"
                                                    onChange={this.handleSorting} checked={this.state.sortOption === 'sort-az'}/>
                                                <Form.Check inline label={t('PT0102')} name="sorting" type="radio" value="sort-latest"
                                                    onChange={this.handleSorting} checked={this.state.sortOption === 'sort-latest'}/>
                                                <Form.Check inline label={t('PT0103')} name="sorting" type="radio" value="sort-story"
                                                    onChange={this.handleSorting} checked={this.state.sortOption === 'sort-story'}/>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <hr className="d-none d-md-block"/>
                                </Container>

                                <Container>
                                {
                                    (this.state.eventsList==null) ?
                                        <div className="loading"></div>
                                        :
                                        <EventsList eventslist={this.state.eventsList} pts={this.state.postsToShow}/>
                                }
                                </Container>

                            </main>

                        </div>
                    </Layout>
                )
            }}
            </NamespacesConsumer>
        )
    }
}

export default withI18next()(EventsListPage)

export const query = graphql`
    query getEventsListPageLang($lng: String!) {

        locales: allLocale(filter: { lng: { eq: $lng } , ns: { eq: "messages" } }) {
            ...LocaleFragment
        }

        en: allLocale(filter: { lng: { eq: "en", }, ns: { eq: "messages" } }) {
            ...LocaleFragment
        }
    }
`
