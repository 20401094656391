
exports.getTrans = ( list ) => {

    return new Promise(resolve => {
        let data = {}
        list.forEach(({node}) => {
            // console.log(node)
            data = JSON.parse(node.data)
            resolve(data)
        })
    })

}